import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import { PanelType } from '@aurora/shared-client/components/common/Panel/enums';
import Panel, {
  PanelBody,
  PanelFooter,
  PanelHeader
} from '@aurora/shared-client/components/common/Panel/Panel';
import type { AppContextInterface } from '@aurora/shared-client/components/context/AppContext/AppContext';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import MembershipTypeForNode from '@aurora/shared-client/components/memberships/MembershipTypeForNode/MembershipTypeForNode';
import NodeAvatar from '@aurora/shared-client/components/nodes/NodeAvatar/NodeAvatar';
import NodeDescription from '@aurora/shared-client/components/nodes/NodeDescription/NodeDescription';
import NodeIcon from '@aurora/shared-client/components/nodes/NodeIcon/NodeIcon';
import NodeTitle from '@aurora/shared-client/components/nodes/NodeTitle/NodeTitle';
import useDateTime from '@aurora/shared-client/components/useDateTime';
import Icons from '@aurora/shared-client/icons';
import type {
  MembershipApplicable,
  ParentNode,
  UserSorts
} from '@aurora/shared-generated/types/graphql-schema-types';
import { SortDirection } from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeCardSize, NodeType } from '@aurora/shared-types/nodes/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { TextAlignment } from '@aurora/shared-types/texts/enums';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { ItemType, NodeViewVariant } from '../../../../types/enums';
import { MemberRole, SubscriptionActionVariant } from '../../../../types/enums';
import type {
  MembershipTypeForNodeFragment,
  NodeFollowersCountFragment,
  NodeMembersCountFragment,
  NodeMembershipViewNodeModelFragment,
  NodeViewFragment
} from '../../../../types/graphql-types';
import type { ItemViewVariantFC } from '../../../entities/types';
import useGroupHubDetails from '../../../grouphubs/useGroupHubDetails';
import UserLink from '../../../users/UserLink/UserLink';
import useTranslation from '../../../useTranslation';
import NodeFollowersCount from '../../NodeFollowersCount/NodeFollowersCount';
import NodeLatestActivityTime from '../../NodeLatestActivityTime/NodeLatestActivityTime';
import NodeLink from '../../NodeLink/NodeLink';
import NodeMembersCount from '../../NodeMembersCount/NodeMembersCount';
import NodeSubscriptionAction from '../../NodeSubscriptionAction/NodeSubscriptionAction';
import NodeTopicsCount from '../../NodeTopicsCount/NodeTopicsCount';
import NodeViewDrawer from '../../NodeViewDrawer/NodeViewDrawer';
import localStyles from './NodeViewCard.module.pcss';

const MemberListForNodeModal = dynamic(
  () => import('../../../memberships/MemberListForNodeModal/MemberListForNodeModal'),
  {
    ssr: false
  }
);

/**
 * Node View - Card
 *
 * @author Adam Ayres, Martin Sandoval, Willi Hyde, Amit Agrawal
 */
const NodeViewCard: ItemViewVariantFC<NodeViewFragment, ItemType.NODE, NodeViewVariant.CARD> = ({
  entity: node,
  className,
  nodeTitleClassName,
  nodeIconSize,
  useNodeAvatar,
  useNodeTitle,
  useNodeDescription,
  useNodeLatestActivityTime,
  useNodeMembersCount,
  useNodeMembersCountText,
  useNodeMembershipType,
  useNodeTopicsCount,
  useNodeOwners,
  useNodeCreationDate,
  useClickableCard,
  panelSize,
  useNodeAction,
  useNodeFollowersCount,
  useNodePostCount,
  header: Header = null,
  useUserHoverCard,
  useUnreadMessagesCount,
  textKey,
  useTextDescription,
  useChildNodes,
  nodeViewDrawerId,
  handleNodeViewDrawerAction,
  nodeCardSize = NodeCardSize.LG,
  textAlignment = TextAlignment.CENTER,
  descriptionClampLines = 2,
  isNestedNode,
  titleAs
}) => {
  const cx = useClassNameMapper(localStyles);
  const {
    formatMessage,
    formatMessageAsNode,
    loading: textLoading,
    FormattedMessage,
    intl
  } = useTranslation(EndUserComponent.NODE_VIEW_CARD);
  const { formatRelativeTime, formatAbsoluteDateTime } = useDateTime();
  const { contextNode } = useContext<AppContextInterface>(AppContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const showOwners =
    (node as unknown as MembershipApplicable).members?.edges?.length > 0 && useNodeOwners;
  const showFollowersCount =
    (node as NodeFollowersCountFragment).subscriptionsCount > 0 && useNodeFollowersCount;
  const ownerListSize = 5;
  const childrenEdges = (node as ParentNode)?.children?.edges;
  const showNodeViewDrawerAction = !!(
    useChildNodes &&
    childrenEdges?.length &&
    node.nodeType !== NodeType.GROUPHUB
  );
  const canRenderNodeDrawer = nodeViewDrawerId === node.id;
  const showMembershipInfo = useNodeMembershipType && 'membershipType' in node;
  const showNodeMemberCount = useNodeMembersCount && showMembershipInfo;

  const {
    queryResult: { data: groupHubData }
  } = useGroupHubDetails(
    {
      id: contextNode.id,
      useMembershipInformation: true
    },
    !showOwners
  );

  const sorts: UserSorts = {
    joinDate: {
      direction: SortDirection.Desc
    }
  };

  if (textLoading) {
    return null;
  }

  const useXsAvatar =
    (nodeCardSize === NodeCardSize.SM && isNestedNode) || nodeCardSize === NodeCardSize.XS;

  /**
   * Renders the node avatar
   */
  function renderNodeAvatar(): React.ReactElement {
    return (
      <NodeAvatar
        size={nodeIconSize}
        node={node}
        className={cx(
          'lia-g-card-avatar lia-avatar',
          {
            'lia-avatar-sm': nodeCardSize === NodeCardSize.SM || isNestedNode
          },
          { 'lia-avatar-xs': useXsAvatar }
        )}
        fallback={(): JSX.Element => (
          <NodeIcon
            size={nodeIconSize}
            node={node}
            className={cx(
              'lia-g-card-avatar lia-avatar',
              {
                'lia-avatar-sm': nodeCardSize === NodeCardSize.SM || isNestedNode
              },
              { 'lia-avatar-xs': useXsAvatar }
            )}
            useFrame
          />
        )}
      />
    );
  }

  /**
   * Renders the node title
   */
  function renderNodeTitle(): React.ReactElement {
    return (
      <NodeLink
        node={node}
        className={cx('lia-g-card-title lia-g-card-item', {
          'stretched-link': useClickableCard
        })}
      >
        <NodeTitle
          className={cx(nodeTitleClassName, 'lia-g-mb-0', {
            'lia-node-title': nodeCardSize === NodeCardSize.SM || nodeCardSize === NodeCardSize.XS
          })}
          node={node}
          as={titleAs}
        />
      </NodeLink>
    );
  }

  /**
   * Renders the node description
   */
  function renderNodeDescription(): React.ReactElement {
    return (
      <NodeDescription
        node={node}
        as="span"
        useTextDescription={useTextDescription}
        clampLines={descriptionClampLines}
        className={cx(`lia-g-card-subtitle lia-g-card-item`)}
      />
    );
  }

  /**
   * Renders the node latest activity time
   */
  function renderNodeLatestActivityTime(): React.ReactElement {
    return (
      <NodeLatestActivityTime
        node={node}
        as="span"
        className={cx('lia-g-card-meta lia-g-card-item')}
        textKey={textKey}
      />
    );
  }

  /**
   * Renders the unread message count on node
   */
  function renderUnreadMessagesCount(): React.ReactElement {
    return (
      <span className={cx('font-weight-bold lia-g-card-meta lia-g-card-item lia-g-divider')}>
        {formatMessage('unreadCount', { count: node.unreadMessagesCount })}
      </span>
    );
  }

  /**
   * Renders the node membership type
   */
  function renderNodeMembershipType(): React.ReactElement {
    if (!showMembershipInfo) {
      return null;
    }

    const { hideOpen, useNodeType } =
      useNodeMembershipType === true
        ? { useNodeType: false, hideOpen: true }
        : useNodeMembershipType;

    return (
      <MembershipTypeForNode
        className={cx('lia-g-card-item lia-g-subheading lia-card-membership-badge')}
        node={node as MembershipTypeForNodeFragment}
        useIcon
        useNodeType={useNodeType}
        hideOpen={hideOpen}
      />
    );
  }

  /**
   * Renders the node subscription action.
   */
  function renderNodeActionButton(): React.ReactElement {
    return <NodeSubscriptionAction node={node} variant={SubscriptionActionVariant.BUTTON} />;
  }

  /**
   * @param owners - Owners of the membership node for which this component is being displayed.
   * @param ownersCount - owners count for the membership node
   *
   * @return - List of Owners as user links.
   */
  function renderOwnerList(owners, ownersCount): React.ReactNode {
    const shouldShowOwnerListModal = ownersCount > ownerListSize;
    const ownersList = owners.edges.map(owner => (
      <UserLink
        key={owner.node.uid}
        user={owner.node}
        className={cx('text-muted')}
        useHoverCard={useUserHoverCard}
      >
        {owner.node.login}
      </UserLink>
    ));
    const ownerListText = (...chunks): React.ReactNode => (
      <span
        onClick={() => setShowModal(true)}
        role="presentation"
        className={cx('lia-show-owner-list-text')}
      >
        {chunks}
      </span>
    );
    return formatMessageAsNode('ownedBy', {
      owners: intl.formatList(ownersList, {
        type: shouldShowOwnerListModal ? 'unit' : 'conjunction'
      }),
      text: shouldShowOwnerListModal ? (
        <FormattedMessage
          id="showOwnerListText"
          values={{
            span: ownerListText,
            ownersCount: ownersCount - ownerListSize
          }}
        />
      ) : null
    });
  }

  /**
   * Renders the node owners
   */
  function renderNodeOwners(): React.ReactElement {
    const { members: owners, ownersCount } = node as unknown as MembershipApplicable;
    return (
      <span className={cx('lia-g-card-meta lia-g-card-item')}>
        {renderOwnerList(owners, ownersCount)}
      </span>
    );
  }

  /**
   * Renders the node creation date
   */
  function renderNodeCreationDate(): React.ReactElement {
    return (
      <span
        className={cx('lia-g-card-meta lia-g-card-item')}
        title={formatAbsoluteDateTime(node.creationDate)}
        suppressHydrationWarning={true}
      >
        {formatMessage('creationDate', {
          creationDate: formatRelativeTime(node.creationDate)
        })}
      </span>
    );
  }

  /**
   * Renders the node topic count
   */
  function renderNodeTopicsCount(): React.ReactElement {
    return (
      <NodeTopicsCount
        useIcon={false}
        useNeutralLabel
        node={node}
        className={cx('lia-g-card-count lia-g-card-meta lia-g-divider')}
        iconSize={IconSize.PX_14}
        iconColor={IconColor.GRAY_600}
      />
    );
  }

  /**
   * Renders the node post count
   */
  function renderNodePostCount(): React.ReactElement {
    return (
      <NodeTopicsCount
        useIcon={false}
        node={node}
        className={cx('lia-g-card-meta lia-g-card-item')}
      />
    );
  }

  /**
   * Renders the node member count
   */
  function renderNodeMembersCount(): React.ReactElement {
    return (
      <NodeMembersCount
        useIcon
        useText={useNodeMembersCountText}
        node={node as NodeMembersCountFragment}
        className={cx('lia-g-card-count')}
        iconColor={IconColor.GRAY_600}
        iconSize={IconSize.PX_14}
      />
    );
  }

  /**
   * Renders the node followers count
   */
  function renderNodeFollowersCount(): React.ReactElement {
    return (
      <NodeFollowersCount
        node={node as NodeFollowersCountFragment}
        className={cx('lia-g-card-count')}
      />
    );
  }

  const useHeader = Header !== null;

  /**
   * Renders the header
   */
  function renderHeader(): React.ReactElement {
    return (
      useHeader && (
        <PanelHeader className={cx('lia-g-card-header')}>
          <Header />
        </PanelHeader>
      )
    );
  }

  /**
   * Toggle drawer state on click
   */
  function toggleDrawer() {
    if (nodeViewDrawerId !== node.id) {
      handleNodeViewDrawerAction(node);
    } else {
      handleNodeViewDrawerAction(null);
    }
  }

  /**
   * Renders the node view drawer action button
   */
  function renderNodeViewDrawerAction(): React.ReactElement {
    return (
      <Button
        onClick={() => toggleDrawer()}
        className={cx('lia-toggle lia-g-icon-btn', { 'stretched-link': showNodeViewDrawerAction })}
        variant={ButtonVariant.UNSTYLED}
        data-testid="NodeViewDrawerButton"
        aria-label={formatMessage('nodeViewDrawerBtn', { place: node.title })}
        aria-expanded={nodeViewDrawerId === node.id}
        title={formatMessage('drawerActionTooltip')}
      >
        <Icon
          icon={Icons.ChevronDownIcon}
          color={IconColor.GRAY_600}
          size={IconSize.PX_16}
          className={cx('lia-node-drawer-icon', { 'lia-is-open': nodeViewDrawerId === node.id })}
        />
      </Button>
    );
  }

  const showNodeTopicsCount = useNodeTopicsCount && node.topicsCount > 0;
  const showNodeLatestActivity = useNodeLatestActivityTime && node.topicsCount > 0;
  const showNodeDescription = useNodeDescription && node.description;
  const showUnreadMessagesCount = useUnreadMessagesCount && node.unreadMessagesCount > 0;
  const useBody =
    useNodeTitle ||
    useNodeAvatar ||
    showNodeDescription ||
    showNodeLatestActivity ||
    showUnreadMessagesCount ||
    showMembershipInfo ||
    useNodeAction ||
    useNodePostCount;

  const useFooter = showNodeMemberCount || showNodeViewDrawerAction || showFollowersCount;

  const useXsGrid =
    nodeCardSize === NodeCardSize.XS &&
    (textAlignment === TextAlignment.LEFT || textAlignment === TextAlignment.RIGHT);

  const showMessageCounts = showNodeTopicsCount || showUnreadMessagesCount;
  const showDivider =
    showNodeLatestActivity || showMessageCounts || showMembershipInfo || useNodeAction;

  /**
   * Renders the body card
   */
  function renderBodyCard(): React.ReactElement {
    return (
      useBody && (
        <PanelBody
          className={cx(
            'lia-g-card-body',
            { 'lia-g-card-left lia-card-grid-left': textAlignment === TextAlignment.LEFT },
            { 'lia-g-card-right lia-card-grid-right': textAlignment === TextAlignment.RIGHT },
            { 'lia-g-card-center': textAlignment === TextAlignment.CENTER },
            { 'lia-g-card-body-is-clickable': useClickableCard || showNodeViewDrawerAction },
            { 'lia-card-grid': useXsGrid }
          )}
        >
          {useNodeAvatar && renderNodeAvatar()}
          <div className={cx('lia-body-content')}>
            {useNodeTitle && renderNodeTitle()}
            {showNodeDescription && renderNodeDescription()}
            {useNodePostCount && renderNodePostCount()}
            {showOwners && renderNodeOwners()}
            {useNodeCreationDate && renderNodeCreationDate()}
            {showDivider && <div className={cx('lia-divider')} aria-hidden="true"></div>}
            {showNodeLatestActivity && renderNodeLatestActivityTime()}
            {showMessageCounts && (
              <section className={cx('lia-counts')}>
                {showNodeTopicsCount && renderNodeTopicsCount()}
                {showUnreadMessagesCount && renderUnreadMessagesCount()}
              </section>
            )}
            {showMembershipInfo && renderNodeMembershipType()}
            {useNodeAction && renderNodeActionButton()}
          </div>
        </PanelBody>
      )
    );
  }

  /**
   * Renders the footer card
   */
  function renderFooterCard(): React.ReactElement {
    return (
      useFooter && (
        <PanelFooter
          className={cx(
            'lia-card-footer',
            'lia-g-card-footer',
            { 'lia-g-card-left': textAlignment === TextAlignment.LEFT },
            { 'lia-g-card-right': textAlignment === TextAlignment.RIGHT },
            { 'lia-g-card-center': textAlignment === TextAlignment.CENTER }
          )}
        >
          {showFollowersCount && renderNodeFollowersCount()}
          {showNodeMemberCount && renderNodeMembersCount()}
          {showNodeViewDrawerAction && renderNodeViewDrawerAction()}
        </PanelFooter>
      )
    );
  }

  return (
    (useHeader || useBody || useFooter) && (
      <>
        <Panel
          type={PanelType.DIVIDER}
          size={panelSize}
          className={cx(
            className,
            'lia-g-card lia-card',
            { 'lia-node-view-card': canRenderNodeDrawer },
            {
              'lia-g-card-sm': nodeCardSize === NodeCardSize.SM
            },
            {
              'lia-g-card-xs': nodeCardSize === NodeCardSize.XS
            }
          )}
          data-testid="NodeViewCard"
        >
          {renderHeader()}
          {renderBodyCard()}
          {renderFooterCard()}
        </Panel>
        {canRenderNodeDrawer && <NodeViewDrawer node={node} cardSize={nodeCardSize} />}
        {showModal && (
          <MemberListForNodeModal
            show={showModal}
            closeCallback={() => setShowModal(false)}
            node={groupHubData?.groupHub as NodeMembershipViewNodeModelFragment}
            sorts={sorts}
            memberRole={MemberRole.OWNER}
          />
        )}
      </>
    )
  );
};

export default NodeViewCard;
